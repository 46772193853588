body {
  font-family: 'Poppins', sans-serif;
  background: #192734;
  margin: 0;
}

* {
  box-sizing: border-box
}

.center_circle_image {

  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 250px;
  width: 250px;
  
}

.anchor {
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
}

/*JUMBOTRON*/
.jumbotron {
  max-width: 100%;
  height: 100vh; 
  background-color: rgb(17, 24, 39);
  filter: "brightness(75%)";
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url("img/warrior2.jpg");
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5; /*no bottom margin*/
  
  display: flex;
  flex-direction: column;
}

.jumbotron-text {
  /*TAGLINE UNDER NAME*/
  font-weight: 400;
  font-size: 1.7em;
  color: white;
  text-align: center;
  margin-top: 10%;
}
@media (max-width: 625px) {
  .jumbotron-text {
     /*TAGLINE UNDER NAME*/
    font-weight: 400;
    font-size: 1.3em;
    color: white;
    text-align: center;
    margin-top: 0%;
  }
}

.jumbotron-text h1 {
  /*YOUR NAME*/
  font-weight: 900;
  font-size: 3em;
  margin-bottom: 0;
}

.jumbotron-text p {
  margin: 0;
}

h1 {
  font-weight: 600;
  color: #FFC300;
}

h2 {
  font-weight: 600;
  font-size: 2.6rem;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  color: #DAF7A6;
}

h3 {
  font-weight: 600;
  color: white;
}

h4 {
  font-weight: 500;
  color: white;
}

.flex-container {
  display: flex;
  flex-direction: column;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 200px) {
  .flex-container {
    flex-direction: column;
  }
}

.box {
  display: flex;
}