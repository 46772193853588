.container-about {
  margin: 50px auto;
  max-width: 1250px;
}
@media (max-width: 625px) {
  .container-about {
    padding: 0px;
  }
}

.container {
  margin: 50px auto;
  max-width: 675px;
}
@media (max-width: 625px) {
  .container {
    padding: 0px;
  }
}

.image 
{
    height: 20pc;
    width: 100%;
    border-radius: 10px;
}

.image.image1 
{
    background: url("../img/badlands.jpg");
    background-size: cover;
}

.image.image2 
{
    background: url("../img/joshuaTree.JPG");
    background-size: cover;
}

.image.image3 
{
    background: url("../img/lajolla.JPG");
    background-size: cover;
}

.image.image4 
{
    background: url("../img/roxborough.JPG");
    background-size: cover;
}

.image.image5 
{
    background: url("../img/yosemite.jpg");
    background-size: cover;
}

.image.image6 
{
    background: url("../img/deathvalley.jpg");
    background-size: cover;
}

* {
  box-sizing: border-box;
}


* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  vertical-align: top;
}

.centered{
  text-align: center;
}

.gallery {
  display: flex;
  margin: 10px auto;
  width: auto;
  max-height: 800px;
  position: relative;
  float: center;
  padding-top: 66.6666666667%;
}

@media screen and (min-width: 675px) {
  .gallery {
    padding-top: 500px;
    flex-wrap: wrap;
  }

  .gallery__thumb {
    width: 156px;
    height: 156px;
    padding-top: 20px;
    margin: 6px;
    display: block;
  }
}

@media screen and (max-width: 675px) {
  .gallery {
    padding-top: 225px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .gallery__img {
    position: absolute;
    max-width: 300px;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .gallery__thumb {
    width: 124px;
    height: 124px;
    padding-top: 6px;
    margin: 6px;
    display: block;
  }
}

.gallery__img {
  position: absolute;
  max-height: 550px;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.gallery__selector {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.gallery__selector:checked + .gallery__img {
  opacity: 1;
}

.gallery__selector:checked ~ .gallery__thumb > img {
  box-shadow: 0 0 0 3px #0be2f6;
}

* {
    -webkit-user-drag: auto;
    user-select: auto;
    box-sizing: border-box;
  }
  
  .rpc-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .rpc-content-wrapper {
    white-space: nowrap;
    cursor: grab;
  }
  
  .rpc-content-item {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }
  
  .rpc-content-control-btns {
    display: flex;
    justify-content: center;
  }
  
  .rpc-content-controls button {
    font-family: sans-serif;
    border: 0;
    outline: none;
    background: black;
    padding: 10px 20px;
    margin: 8px 1px;
    cursor: pointer;
  }
  .rpc-content-controls button:hover {
    background: black;
  }
  .rpc-content-controls button:active {
    background: black;
  }
  .rpc-content-controls .rpc-prev-btn {
    border-radius: 7px 0 0 7px;
  }
  .rpc-content-controls .rpc-next-btn {
    border-radius: 0 7px 7px 0;
  }
  
  .rpc-content-controls .rpc-content-control-dots {
    display: flex;
    justify-content: center;
  }
  .rpc-content-controls .rpc-content-control-dots .rpc-control-dot {
    border-radius: 10px;
    height: 10px;
    width: 10px;
    background: black;
    margin: 5px;
    cursor: pointer;
  }
  .rpc-content-controls .rpc-content-control-dots .rpc-control-dot.active {
    background: #4286f4a2;
  }
  .rpc-content-controls .rpc-content-control-dots .rpc-control-dot.hide {
    display: none;
  } 